import React, { useEffect, useState } from "react";
import { Timestamp } from "../../service/src";

interface IDateTimeTag {
    timestamp: Timestamp;
}

function DateTimeTag(props: IDateTimeTag) {
    const [timestamp, setTimestamp] = useState<string>("N/A");

    useEffect(() => {
        if (props.timestamp !== undefined) {
            setTimestamp(new Date(props.timestamp.seconds * 1000).toLocaleString());
        }
    }, [props.timestamp]);

    return (
        <span>{timestamp}</span>
    );
}

export default DateTimeTag;
